import React from 'react'
import { Link } from 'gatsby'

import { Helmet } from 'react-helmet'

import projectStyles from '../style.module.css'
import styles from './linux-r-d-p.module.css'

const LinuxRDP = (props) => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>Linux RDP - Covert_Bear</title>
        <meta property="og:title" content="Linux RDP - Covert_Bear" />
      </Helmet>
      <div data-role="Header" className={styles['navbar-container']}>
        <div className={styles['navbar']}>
          <div className={styles['left-side']}>
            <Link to="/" className={styles['navlink']}>
              <img
                alt="image"
                src="/playground_assets/icon-white-200h.png"
                className={styles['image']}
              />
            </Link>
            <div data-type="BurgerMenu" className={styles['burger-menu']}>
              <svg viewBox="0 0 1024 1024" className={styles['icon']}>
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
            <div className={styles['links-container']}>
              <Link
                to="/"
                className={` ${styles['link']} ${projectStyles['anchor']} `}
              >
                Home
              </Link>
              <Link
                to="/pricing"
                className={` ${styles['link01']} ${projectStyles['anchor']} `}
              >
                <span>Pricing</span>
              </Link>
              <Link
                to="/v-p-n"
                className={` ${styles['link02']} ${projectStyles['anchor']} `}
              >
                VPN
              </Link>
              <Link
                to="/windows-r-d-p"
                className={` ${styles['link03']} ${projectStyles['anchor']} `}
              >
                Windows RDP
              </Link>
              <a
                href="#linux_heading"
                className={` ${styles['link04']} ${projectStyles['anchor']} `}
              >
                Linux RDP
              </a>
            </div>
          </div>
          <div className={styles['right-side']}>
            <Link
              to="/contact1"
              className={` ${styles['cta-btn']} ${projectStyles['anchor']} ${projectStyles['button']} `}
            >
              Contact Us
            </Link>
          </div>
          <div data-type="MobileMenu" className={styles['mobile-menu']}>
            <div className={styles['container1']}>
              <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                className={styles['image1']}
              />
              <div data-type="CloseMobileMenu" className={styles['close-menu']}>
                <svg viewBox="0 0 1024 1024" className={styles['icon2']}>
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className={styles['links-container1']}>
              <a
                href="#resources"
                className={` ${styles['link05']} ${projectStyles['anchor']} `}
              >
                Resources
              </a>
              <span
                className={` ${styles['link06']} ${projectStyles['anchor']} `}
              >
                Inspiration
              </span>
              <a
                href="#process"
                className={` ${styles['link07']} ${projectStyles['anchor']} `}
              >
                Process
              </a>
              <span
                className={` ${styles['link08']} ${projectStyles['anchor']} `}
              >
                Our story
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['services']}>
        <div className={styles['heading-container']}>
          <h1
            id="linux_heading"
            className={` ${styles['text01']} ${projectStyles['section-heading']} `}
          >
            Covert Bear Linux RDP
          </h1>
          <span
            className={` ${styles['text02']} ${projectStyles['section-text']} `}
          >
            We&apos;ve got you covered
          </span>
          <div className={styles['container2']}>
            <span className={styles['text03']}>
              Page Under Construction. For detailed information please
            </span>
            <Link to="/contact1" className={styles['navlink1']}>
              Contact Us.
            </Link>
          </div>
        </div>
        <div className={styles['cards-container']}>
          <div className={styles['service-card']}>
            <h3
              className={` ${styles['text04']} ${projectStyles['card-heading']} `}
            >
              A (Simpler) Computer away from Home
            </h3>
            <img
              alt="image"
              src="/playground_assets/k_desktop-1200w.png"
              className={styles['image2']}
            />
            <span
              className={` ${styles['text05']} ${projectStyles['card-text']} `}
            >
              <span>
                • A Linux RDP (Remote Desktop Portal) will get you a Remote
                (meaning somewhere else in the World - you can choose where)
                Computer running the Linux OS.
              </span>
            </span>
            <div
              className={` ${styles['container3']} ${projectStyles['card-text']} `}
            >
              <span>• Similar to our</span>
              <Link
                to="/windows-r-d-p"
                className={` ${styles['navlink2']} ${projectStyles['card-text']} `}
              >
                Windows RDP.
              </Link>
            </div>
            <span
              className={` ${styles['text08']} ${projectStyles['card-text']} `}
            >
              <span>
                • A VPN would be faster, but the Linux RDP is still better than
                a Windows RDP.
              </span>
              <br></br>
              <span>​</span>
              <br></br>
              <span className={styles['text11']}>
                So why do we offer a Windows RDP?
              </span>
              <br></br>
              <span>
                • Freedom of choice. Besides, it still gets the job done at the
                end of the day.
              </span>
              <br></br>
              <span>​</span>
              <br></br>
              <span className={styles['text14']}>
                I&apos;ve never used Linux before
              </span>
              <br></br>
              <span>
                • Don&apos;t worry about it, it looks similar to Windows (image
                above). There might be a few different apps compared to what you
                might have used but it&apos;ll function the same.
              </span>
              <br></br>
              <span>
                • The Web Browser will let you access the Internet, save
                bookmarks, etc.
              </span>
              <br></br>
              <span>
                • It&apos;ll come with LibreOffice which is similar to Microsoft
                Office.
              </span>
              <br></br>
              <span>
                • Covert Bear is here to help you out if you ever get stuck.
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className={styles['section-separator']}></div>
      <div className={styles['footer-container']}>
        <div className={styles['footer']}>
          <div className={styles['copyright-container']}>
            <span
              className={` ${styles['link09']} ${projectStyles['anchor']} `}
            >
              <span>© Copyright, 2021</span>
              <br></br>
              <span>Covert Bear, All Rights Reserved</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinuxRDP
